import React from 'react';
import {
  TextField,
  NumberField,
  DateField,
  Show,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import { PartnershipReferenceField } from '../partnership/utils';
import { JsonField } from 'react-admin-json-view';
import { VisibilityToggleButton } from '../utils';
import { AggregatorInstanceField } from './util';

const AggregatorLaunchpadShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <AggregatorInstanceField />
      <TextField source="model" />
      <PartnershipReferenceField source="partnership" />
      <NumberField source="jobFrequency" label="Job frequency (in hours)" />
      <TextField source="uri" />
      <TextField source="contentType" />
      <JsonField source="options" label="Symfony httpclient options" />
      <FunctionField
        source="aggregatorType"
        render={(record) =>
          record != null
            ? [{ id: 'remote', name: 'Remote' }].find((r) => r.id === record.aggregatorType)?.name
            : undefined
        }
      />
      <DateField source="createdAt" label="Begin date" />
      <VisibilityToggleButton resource="remote_aggregator_launchpads" />
    </SimpleShowLayout>
  </Show>
);

export default AggregatorLaunchpadShow;
