import React from 'react';
import { SelectInput, FunctionField } from 'react-admin';
import { PropTypes } from 'prop-types';
import { Chip } from '@material-ui/core';

export const AGGREGATOR_MODEL_DAZN = 'Bundles\\ContentAggregator\\Model\\DaznNewsAggregator';
export const AGGREGATOR_MODELS_MAP = [{ id: AGGREGATOR_MODEL_DAZN, name: 'Dazn News' }];

export const AggregatorModelSelectInput = (props) => (
  <SelectInput
    {...props}
    translateChoice={false}
    choices={AGGREGATOR_MODELS_MAP}
    optionValue="id"
    optionText="name"
  />
);
export const AggregatorModelField = (props) => (
  <FunctionField
    {...props}
    render={(record) =>
      AGGREGATOR_MODELS_MAP.find((a) => a.id === record?.model)?.name ?? record?.model
    }
  />
);

export const AggregatorInstanceField = (props) => {
  const isActive = (record) => record?.instances > 0;
  return (
    <FunctionField
      {...props}
      render={(record) => (
        <Chip
          label={isActive(record) ? 'Active' : 'Not active'}
          style={{
            backgroundColor: isActive(record) ? '#47e02e' : '#e14f41',
            color: '#ffffff',
          }}
        />
      )}
    />
  );
};
AggregatorInstanceField.propTypes = {
  record: PropTypes.object,
};
