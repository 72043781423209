import React from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  TextInput,
} from 'react-admin';
import { AggregatorModelSelectInput } from './util';
import { PartnershipSelectInput } from '../partnership/utils';
import { JsonInput } from 'react-admin-json-view';

const AggregatorLaunchpadEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <AggregatorModelSelectInput source="model" validate={[required()]} />
        <PartnershipSelectInput />
        <NumberInput
          source="jobFrequency"
          label="Job frequency (in hours)"
          validate={[required()]}
        />
        <TextInput source="uri" />
        <SelectInput
          source="contentType"
          choices={[
            { id: 'json', name: 'JSON' },
            { id: 'xml', name: 'XML' },
          ]}
          translateChoice={false}
        />
        <JsonInput source="options" label="Symfony httpclient options" />
        <SelectInput
          source="aggregatorType"
          choices={[{ id: 'remote', name: 'Remote' }]}
          translateChoice={false}
          validate={[required()]}
        />
        <BooleanInput label="Visibile" source="visible" initialValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default AggregatorLaunchpadEdit;
