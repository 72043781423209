import React from 'react';
import {
  BooleanInput,
  Create,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  required,
} from 'react-admin';
import mediaCreator from '../mediaCreator';

const PartnerCreate = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Create
      {...props}
      transform={async (data) => {
        try {
          const createIcon = mediaCreator({
            dataProvider,
            notify,
            mediaFieldLabel: 'icon',
            fileLabel: 'icon',
          });

          const _data = await createIcon(data);
          // if cover exists
          if (_data['cover']) {
            const createCover = mediaCreator({
              dataProvider,
              notify,
              mediaFieldLabel: 'cover',
              fileLabel: 'cover',
            });

            return await createCover(_data);
          }

          return _data;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        return data;
      }}
    >
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
        <FileInput
          validate={[required()]}
          source="icon"
          label="Icona partner"
          placeholder={<p>Trascina qui l&apos;immagine, o clicca qui per selezionarla</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="cover"
          label="Cover partner"
          placeholder={<p>Trascina qui l&apos;immagine, o clicca qui per selezionarla</p>}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="primaryColor" />
        <BooleanInput label="Visibile" source="visible" initialValue={true} />
      </SimpleForm>
    </Create>
  );
};

export default PartnerCreate;
