import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  FileInput,
  FileField,
  useDataProvider,
  useNotify,
  BooleanInput,
  required,
} from 'react-admin';
import AsideView from '../asideView';
import mediaCreator from '../mediaCreator';

const PartnerEdit = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  return (
    <Edit
      aside={<AsideView />}
      {...props}
      transform={async (data) => {
        try {
          let _data = data;

          if (_data['tmp_icon']) {
            const createIcon = mediaCreator({
              dataProvider,
              notify,
              mediaFieldLabel: 'icon',
              fileLabel: 'tmp_icon',
            });

            _data = await createIcon(data);
          }
          // if cover exists
          if (_data['tmp_cover']) {
            const createCover = mediaCreator({
              dataProvider,
              notify,
              mediaFieldLabel: 'cover',
              fileLabel: 'tmp_cover',
            });

            return await createCover(_data);
          }

          return _data;
        } catch (error) {
          notify(`Errore inatteso: ${error.message}`, 'warning');
        }
        return data;
      }}
    >
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="description" />
        <FileInput
          source="tmp_icon"
          label="Icona partner"
          placeholder={
            <p>
              Se vuoi sostituire la cover, trascina qui un file immagine, altrimenti verrà mantenuta
              la cover corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="tmp_cover"
          label="Cover partner"
          placeholder={
            <p>
              Se vuoi sostituire la cover, trascina qui un file immagine, altrimenti verrà mantenuta
              la cover corrente
            </p>
          }
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="primaryColor" />
        <BooleanInput label="Visibile" source="visible" />
      </SimpleForm>
    </Edit>
  );
};

export default PartnerEdit;
