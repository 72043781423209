import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
} from 'react-admin';
import {ConfigShowForm} from "./utils";

const ConfigShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="key" label="Key" />
        <ConfigShowForm />
        <BooleanField source="private" />
      </SimpleShowLayout>
    </Show>
);

export default ConfigShow;
