import React from 'react';
import {
  BooleanInput,
  Create,
  DateTimeInput,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  NumberInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import {
  validateTitle,
  validateTournament,
  validateStartDate,
  validateEndDate,
  validateRequired,
} from './validators';
import RichTextInput from 'ra-input-rich-text';
import { tournamentOptionText } from '../utils';

const StageCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={validateTitle} />
        <ReferenceInput
          label="Torneo"
          source="tournament"
          reference="tournaments"
          validate={validateTournament}
        >
          <SelectInput optionText={(record) => tournamentOptionText(record)} />
        </ReferenceInput>
        <RichTextInput
          source="description"
          toolbar={[
            [{ font: [] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image'],
          ]}
        />
        <NumberInput source="sequence" label="Ordinamento" min={0} validate={validateRequired} />
        <JsonInput
          source="extra"
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <DateTimeInput source="startDate" validate={validateStartDate} />
        <DateTimeInput source="endDate" validate={validateEndDate} />
        <BooleanInput label="Your chance" source="yourChance" />
      </SimpleForm>
    </Create>
  );
};

export default StageCreate;
