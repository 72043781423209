import React, { useEffect } from 'react';
import { Button, useNotify, ReferenceInput, SelectInput, useRefresh } from 'react-admin';
import DialogForm from '../DialogForm';
import Icon from '@material-ui/icons/SupervisorAccountSharp';
import { Box } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import authProvider from '../authProvider';
import { apiRequest, HTTP_METHOD_POST, resourceId, tournamentOptionText } from '../utils';
import InfoBox from '../InfoBox';
import { ExcludeSelectInput, getResource } from './users.util';

const UserRoleManagerButton = ({ disabled, userId, user }) => {
  const uid = userId?.length ? userId : user?.permissions?.id ?? resourceId(user?.id || '');
  const [open, setOpen] = useState(false);
  const allowedActions = {
    assign: 'Assegna',
    remove: 'Rimuovi',
    reset: 'Resetta',
  };
  const [action, setAction] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [product, setProduct] = useState(null);
  const [partnership, setPartnership] = useState(null);
  const [userTournaments, setUserTournaments] = useState(null);
  const [userData, setUserData] = useState(null);
  const [tickets, setTickets] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    getResource(uid, 'users').then((u) => setUserData(u));
  }, [uid]);

  const handleInit = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const handleSubmit = async () => {
    if (!authProvider.checkExistingToken()) {
      notify('Login expired, please authenticate again', 'warning');
      return;
    }
    if (!action) {
      notify("Seleziona un'azione", 'error');
      return;
    }
    if (action !== 'reset' && !tournament && !product) {
      notify("Selezionare uno tra 'Torneo Free' e 'Prodotto'", 'error');
      return;
    }
    const payload = {
      action,
      tournament: tournament ?? undefined,
      product: product ?? undefined,
      partnership: partnership ?? undefined,
    };
    const response = await apiRequest({
      path: `/users/${resourceId(uid)}/role/manage`,
      method: HTTP_METHOD_POST,
      payload,
    });
    setOpen(false);
    if (response.ok) {
      response
        .json()
        .then((u) => notify(`User '${u?.name} ${u?.surname}' was updated successfully!`))
        .catch(() => undefined);
      refresh();
    } else {
      response
        .json()
        .then((e) => notify(e?.['hydra:description'] ?? 'Unable to complete operation', 'error'))
        .catch(() => undefined);
    }
  };
  const fetchUserData = async () => {
    const [p, t] = await Promise.all([
      getResource(undefined, 'profile_tournaments', {
        'profile.id': userData.profile?.['id'] ?? userData.profile,
        visible: true,
      }),
      getResource(undefined, 'tickets', { visible: true, state: 'completed', 'owner.id': uid }),
    ]);
    setUserTournaments(p?.map((pt) => pt.tournament));
    setTickets(t?.map((t) => t.product?.id ?? t.product));
  };

  if (!uid) {
    return null;
  }

  return (
    <>
      <Button disabled={disabled} label="Gestisci tornei e prodotti" onClick={handleInit}>
        <Icon />
      </Button>
      <DialogForm
        open={open}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        title="Aggiungi/rimuovi un torneo o prodotto"
        submitLabel={
          action === 'assign'
            ? 'Assegna'
            : action === 'remove'
            ? 'Rimuovi'
            : action === 'reset'
            ? 'Resetta'
            : ''
        }
      >
        <Box display="flex" flexDirection="column">
          <SelectInput
            required
            source="action"
            label={"Seleziona l'azione da eseguire"}
            onChange={(event) => {
              if (event.target.value === 'remove' && null === userTournaments) {
                fetchUserData();
              }
              setAction(event.target.value);
            }}
            choices={Object.entries(allowedActions).map(([k, n]) => ({ id: k, name: n }))}
          ></SelectInput>
          {action === 'assign' && (
            <InfoBox
              text={
                "Questa opzione ti permette di <strong>assegnare un torneo free o un prodotto</strong> all'utente selezionato"
              }
            />
          )}
          {action === 'remove' && (
            <InfoBox
              text={
                "Questa opzione ti permette di <strong>rimuovere un torneo free o un prodotto</strong> all'utente selezionato" +
                (0 === userTournaments?.length
                  ? `<br><strong>${userData?.name} ${userData?.surname} non sta partecipando ad alcun torneo!</strong>`
                  : '')
              }
              level={'warning'}
            />
          )}
          {action === 'reset' && (
            <InfoBox
              text={
                "Attenzione: questa opzione ti permette di <strong>resettare completamente</strong> l'utente selezionato <strong>rimuovendo tutte le partecipazioni ai tornei</strong>!"
              }
              level={'error'}
            />
          )}
          {action && action !== 'reset' && !product && (
            <ReferenceInput
              label="Torneo Free"
              source="tournament"
              reference="tournaments"
              allowEmpty={true}
              onChange={(event) => setTournament(event.target.value)}
              filter={
                action !== 'assign'
                  ? { active: true, premium: false }
                  : { active: true, premium: false, gender: userData.gender }
              }
              required
            >
              <ExcludeSelectInput
                identifiers={action === 'remove' ? userTournaments : null}
                required
                optionText={tournamentOptionText}
              />
            </ReferenceInput>
          )}
          {action && action !== 'reset' && !tournament && (
            <ReferenceInput
              label="Prodotto"
              source="product"
              reference="products"
              allowEmpty={true}
              onChange={(event) => setProduct(event.target.value)}
              filter={{ enable: true }}
              required
            >
              <ExcludeSelectInput
                identifiers={action === 'remove' ? tickets : null}
                required
                optionText={(record) => `${record.title} [${record.type}]`}
              />
            </ReferenceInput>
          )}
          {action === 'assign' && (
            <ReferenceInput
              label="Partnership"
              source="partnership"
              reference="partnerships"
              onChange={(event) => setPartnership(event.target.value)}
            >
              <SelectInput optionText={(record) => `${record.name} [${record.partner.name}]`} />
            </ReferenceInput>
          )}
        </Box>
      </DialogForm>
    </>
  );
};

UserRoleManagerButton.propTypes = {
  disabled: PropTypes.bool,
  userId: PropTypes.string,
  user: PropTypes.object,
};

export default UserRoleManagerButton;
