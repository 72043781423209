import React from 'react';
import {
  BooleanField,
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  ReferenceField,
  ChipField,
  FunctionField,
} from 'react-admin';
import { tournamentOptionText } from '../utils';

const StageShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="description" />
      <ReferenceField label="Torneo" source="tournament" reference="tournaments">
        <FunctionField
          render={(record) => (
            <ChipField source="name" record={{ name: tournamentOptionText(record) }} />
          )}
        />
      </ReferenceField>
      <TextField source="sequence" label="Ordinamento" />
      <DateField source="startDate" showTime={false} />
      <DateField source="endDate" showTime={false} />
      <BooleanField label="Your chance" source="yourChance" />
    </SimpleShowLayout>
  </Show>
);

export default StageShow;
