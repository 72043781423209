import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  useQueryWithStore,
  Loading,
  Error,
  FunctionField,
} from 'react-admin';
import { Box } from '@material-ui/core';

export const PartnerField = ({ record }) => {
  const { loaded, error, data: media } = useQueryWithStore({
    type: 'getOne',
    resource: 'media',
    payload: { id: record?.icon },
  });

  if (record.icon) {
    if (error) {
      return <Error />;
    }
    if (!loaded || !media) {
      return <Loading />;
    }
  }

  if (!record) return null;

  return (
    <Box display="flex" alignItems="center">
      {media != null && (
        <img
          src={media.path}
          alt="partner icon"
          style={{ height: '30px', aspectRatio: 1, borderRadius: '100%' }}
        />
      )}
      <TextField source="name" record={record} />
    </Box>
  );
};
PartnerField.propTypes = {
  record: PropTypes.object,
  props: PropTypes.any,
};

export const PartnerReferenceField = ({ source, reference, label, ...props }) => {
  return (
    <ReferenceField
      label={label ?? 'Partner'}
      source={source ?? 'partner'}
      reference={reference ?? 'partners'}
      link="show"
      sortable={false}
      {...props}
    >
      <FunctionField render={(record) => <PartnerField record={record} />} />
    </ReferenceField>
  );
};
PartnerReferenceField.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};

export const PartnerSelectInput = ({ source, label, reference, ...props }) => {
  return (
    <ReferenceInput
      label={label ?? 'Partner'}
      source={source}
      reference={reference ?? 'partners'}
      link="show"
      sortable={false}
      {...props}
    >
      <SelectInput translateChoice={false} optionText={<PartnerField />} />
    </ReferenceInput>
  );
};
PartnerSelectInput.propTypes = {
  source: PropTypes.string,
  reference: PropTypes.string,
  label: PropTypes.string,
};
