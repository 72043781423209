import React from 'react';
import PropTypes from 'prop-types';
import {
  RichTextField,
  useQueryWithStore,
  Loading,
  Error,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
  PaginationActions,
  ReferenceField,
  FunctionField,
  BooleanField,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';
import { Typography, CardMedia, Card } from '@material-ui/core';
import useAsideStyles from './useAsideStyles';
import { getCloudFrontMedia, getRawUploadUrl } from '../utils';

const PaginationActionsSmall = (props) => (
  <PaginationActions {...props} color="secondary" size="small" />
);

const videoMimeRE = /^video/;

const AsideMedia = ({
  record,
  mediaIds,
  hasTitle,
  descriptionField,
  videoRecord,
  basePath,
  resource,
  version,
  commentRecord,
  isCloudFrontMedia = false,
  isCloudFrontRawMedia,
  backgroundColor,
}) => {
  const classes = useAsideStyles();
  const { loaded, error, data: mediaList } = useQueryWithStore({
    type: 'getMany',
    resource: 'media',
    payload: { ids: mediaIds },
  });
  if (error) {
    return <Error />;
  }
  if (!loaded || !mediaList) {
    return <Loading />;
  }
  const getPath = (path) =>
    isCloudFrontMedia
      ? isCloudFrontRawMedia
        ? getRawUploadUrl(path)
        : getCloudFrontMedia(path)
      : path;
  return (
    <Card className={classes.container} style={{ backgroundColor }}>
      {hasTitle && <Typography variant="h6">{record.title}</Typography>}
      {commentRecord && (
        <SimpleShowLayout record={commentRecord}>
          <TextField label="commento segnalato" source="text" />
        </SimpleShowLayout>
      )}
      {mediaList.map((data, index) => (
        <CardMedia
          key={index}
          component={videoMimeRE.exec(data.mimeType) ? 'video' : 'img'}
          src={data.pathHiRes || getPath(data.path)}
          controls
        />
      ))}
      {descriptionField && <RichTextField source={descriptionField} record={record} />}
      {videoRecord && (
        <SimpleShowLayout
          record={videoRecord}
          basePath={basePath}
          resource={resource}
          version={version}
        >
          <ReferenceManyField
            pagination={<Pagination actions={PaginationActionsSmall} />}
            reference="comments"
            target="video.id"
            sort={{ field: 'createdAt', order: 'DESC' }}
            label="commenti"
          >
            <Datagrid>
              <ReferenceField
                label="User"
                source="owner"
                reference="users"
                link="show"
                sortable={false}
              >
                <FunctionField render={(record) => `${record.surname} ${record.name}`} />
              </ReferenceField>
              <TextField source="text" sortable={false} />
              <BooleanField source="visible" sortable={false} />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      )}
    </Card>
  );
};
AsideMedia.propTypes = {
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  resource: PropTypes.string,
  version: PropTypes.number,
  mediaIds: PropTypes.array.isRequired,
  hasTitle: PropTypes.bool.isRequired,
  descriptionField: PropTypes.string.isRequired,
  videoRecord: PropTypes.object,
  commentRecord: PropTypes.object,
  isCloudFrontMedia: PropTypes.bool,
  isCloudFrontRawMedia: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default AsideMedia;
