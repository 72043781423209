import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'react-admin';
import AsideVideo from './AsideVideo';
import AsideMedia from './AsideMedia';
import AsideProfile from './AsideProfile';
import AsideUser from './AsideUser';
import AsideProfileMissing from './AsideProfileMissing';
import AsideComment from './AsideComment';
import { Typography, Card } from '@material-ui/core';
import useAsideStyles from './useAsideStyles';

const Aside = (props) => {
  const classes = useAsideStyles();

  if (!props.record) return <Loading />;

  switch (props.record['@type']) {
    case 'Partner': {
      const newMediaIds = [];
      if (props.record.icon) newMediaIds.push(props.record.icon);
      if (props.record.cover) newMediaIds.push(props.record.cover);
      return (
        <AsideMedia
          {...props}
          mediaIds={newMediaIds}
          descriptionField="text"
          hasTitle
          isCloudFrontMedia={true}
          isCloudFrontRawMedia={true}
          backgroundColor={props.record?.primaryColor}
        />
      );
    }
    case 'News': {
      const newMediaIds = [];
      if (props.record.cover) newMediaIds.push(props.record.cover);
      if (props.record.video) newMediaIds.push(props.record.video);
      return <AsideMedia {...props} mediaIds={newMediaIds} descriptionField="text" hasTitle />;
    }
    case 'Report':
      switch (props.record.targetClass) {
        case 'App\\Entity\\Comment':
          return <AsideComment {...props} commentId={props.record.targetIRI} />;
        case 'App\\Entity\\Video':
          return (
            <AsideVideo
              {...props}
              videoId={props.record.targetIRI}
              descriptionField="text"
              hasTitle={false}
            />
          );
        case 'App\\Entity\\User':
          return <AsideUser {...props} userId={props.record.targetIRI} />;
        default:
      }
      break;
    case 'Video':
      return (
        <AsideMedia
          {...props}
          mediaIds={[props.record.media]}
          hasTitle={false}
          descriptionField="description"
          videoRecord={props.record}
        />
      );
    case 'Challenge': {
      const challengeMediaIds = [];
      if (props.record.cover) challengeMediaIds.push(props.record.cover);
      if (props.record.example) challengeMediaIds.push(props.record.example);
      return (
        <AsideMedia
          {...props}
          mediaIds={challengeMediaIds}
          descriptionField="description"
          hasTitle
        />
      );
    }
    case 'User':
      return props.record.profile ? (
              <AsideProfile {...props} profileId={props.record.profile} skillTaleId={props.record.skillTaleId}/>
      ) : (
        <AsideProfileMissing {...props} />
      );
    default:
  }

  return (
    <Card className={classes.container}>
      <Typography variant="h6">Unknow content type</Typography>
    </Card>
  );
};

Aside.propTypes = {
  record: PropTypes.object,
};

export default Aside;
